import { TextField, textFieldClasses } from "@mui/material";
import { ChangeEventHandler } from "react";
import styles from './CustomTextField.module.scss';
import { styled, withStyles } from "@mui/material/styles";
import './textfield.css';


type Props = {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    placeholder?: string;
    type?: string;
    error?: string;
    name?: string;
    style?: React.CSSProperties,
    disabled?: boolean,
    maxLength?: number;
    label?: string;
    size?: string;
    isTextArea?: boolean;
    maxRows?: number;
    minRows?: number
    [x: string]: any;
}

const CssTextField = styled(TextField)({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
});

const OverrideTextfield = styled(TextField)(({ theme }) => ({
    '& .Mui-error': {
        fontSize: "1rem"
    },
    '& .MuiInput-root': {
        color: "#666666",
        '& input .Mui-focused ': {
            borderColor: "rgba(14, 13, 17, 0.3)"
        }
    },
    '& .MuiInputBase-input': {
        fontSize: '1.75rem',
        // '&::placeholder': {
        //     fontWeight: 400,
        //     color: "rgba(14, 13, 17, 0.3)",
        // },
    },
}))


const CustomTextField = ({ label, value, onChange, type, error, name, style, disabled, maxLength, size, isTextArea = false, maxRows = 2, minRows = 2, ...restProps }: Props) => {


    return (
        <div className={styles.inputContainer}>
            <div className={styles.label}>{label}</div>
            {/* <OverrideTextfield multiline={isTextArea} maxRows={maxRows} minRows={minRows} fullWidth={true} {...restProps} onChange={onChange} variant="standard" size={"medium"} error={error ? true : false} helperText={error ? error : ''} />
             */}
            <OverrideTextfield inputProps={{ maxLength: maxLength }} multiline={isTextArea} maxRows={maxRows} minRows={minRows} fullWidth={true} {...restProps} onChange={onChange} variant="standard" size={"medium"} error={error ? true : false} helperText={error ? error : ''} sx={{
                borderColor: "#cecece"
            }} />
            {!error ? <p></p> : null}
            {/* <input {...restProps} onChange={onChange} /> */}

        </div>
    );
}

export default CustomTextField;