import { ParagraphType } from "../PrivacyPolicy/data";

export const termsOfService = {
    title: null,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>These terms and conditions outline the rules and regulations for the use of Jigsaw Financial Technology Pvt. Ltd’s Website, located at <a href="https://clickmerito.com">https://clickmerito.com</a>. By accessing this website we assume you accept these terms and conditions. Do not continue to use Merito if you do not agree to take all of the terms and conditions stated on this page.</p>,
            ]
        }

    ]
}

export const acceptance = {
    title: <>Acceptance of the Agreement</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>You must be of legal age to enter into a binding agreement in order to accept the Agreement . If you do not agree to the General Terms, do not use any of our Services. If you agree to the General Terms and do not agree to any Service Specific Terms, do not use the corresponding Service. You can accept the Agreement by checking a checkbox or clicking on a button indicating your acceptance of the Agreement or by actually using the Services.</p>,
            ]
        }

    ]
}

export const Description = {
    title: <>Description of Service</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>We provide ready-made data dashboards for corporations, and businesses ("Service" or "Services"). You may use the Services for your personal and business use or for internal business purpose in the organization that you represent. You may connect to the Services using any Internet browser supported by the Services. You are responsible for obtaining access to the Internet and the equipment necessary to use the Services. You can create and edit content with your user account and if you choose to do so, you can publish and share such content.
                </p>,
            ]
        }

    ]
}

export const Disclaimer = {
    title: <>Disclaimer</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.unorderedList,
            listHeading: <>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</>,
            content: [
                <>
                    Limit or exclude our or your liability for death or personal injury,
                </>,
                <>
                    Limit or exclude our or your liability for fraud or fraudulent misrepresentation,
                </>,
                <>
                    Limit any of our or your liabilities in any way that is not permitted under applicable law,
                </>,
                <>
                    Exclude any of our or your liabilities that may not be excluded under applicable law,
                </>
            ]
        },
        {
            subTitle: null,
            type: ParagraphType.unorderedList,
            listHeading: <>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
            As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</>,
            content: [
            ]
        }]
}

export const Cookies = {
    title: <>Cookies</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>We employ the use of cookies. By accessing Merito, you agreed to use cookies in agreement with the Jigsaw Financial Technology Pvt. Ltd's Privacy Policy.
                Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. The user will be explicitly informed if a cookie will be used by any affiliate partners, advertising partners or any other third party.</p>,
            ]
        }

    ]
}

export const Hyperlinking = {
    title: <>Hyperlinking to our Content</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.unorderedList,
            listHeading: <>The following organizations may link to our Website without prior written approval:</>,
            content: [
                <>
                    Government agencies,
                </>,
                <>
                    search engines,
                </>,
                <>
                    news organizations,
                </>,
                <>
                    online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses, and
                </>,
                <>
                    system wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
                </>
            ]
        },
        {
            subTitle: null,
            type: ParagraphType.unorderedList,
            listHeading: <>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.
            We may consider and approve other link requests from the following types of organizations:</>,
            content: [
                <>
                    Commonly-known consumer and/or business information sources,
                </>,
                <>
                    dot.com community sites,
                </>,
                <>
                    associations or other groups representing charities,
                </>,
                <>
                    online directory distributor,
                </>,
                <>
                    internet portals,
                </>,
                <>
                    accounting, law and consulting firms, and
                </>,
                <>
                    educational institutions and trade associations.
                </>
            ]
        },
        {
            subTitle: null,
            type: ParagraphType.unorderedList,
            listHeading: <>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Jigsaw Financial Technology Pvt. Ltd.; and (d) the link is in the context of general resource information.
            These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.
            If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Jigsaw Financial Technology Pvt. Ltd.. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
            Approved organizations may hyperlink to our Website as follows:</>,
            content: [
                <>
                    By use of our corporate name,
                </>,
                <>
                    By use of the uniform resource locator being linked to,
                </>,
                <>
                    By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site,
                </>
            ]
        },
        {
            subTitle: null,
            type: ParagraphType.unorderedList,
            listHeading: <>No use of Jigsaw Financial Technology Pvt. Ltd.'s logo or other artwork will be allowed for linking absent a trademark license agreement.</>,
            content: []
        }]
}

export const ContentLiability = {
    title: <>Content Liability</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>,
            ]
        }

    ]
}

export const Privacy = {
    title: <>Your Privacy</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p><a href="https://clickmerito.com/privacypolicy">Read our privacy policy</a></p>,
            ]
        }

    ]
}

export const Obligations = {
    title: <>User Sign up Obligations</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>You need to sign up for a user account by providing all required information in order to access or use the Services. If you represent an organization and wish to use the Services for corporate internal use, we recommend that you, and all other users from your organization, sign up for user accounts by providing your corporate contact information. In particular, we recommend that you use your corporate email address. You agree to: (i) provide true, accurate, current and complete information about yourself as prompted by the sign up process; and (ii) maintain and promptly update the information provided during sign up to keep it true, accurate, current, and complete. If you provide any information that is untrue, inaccurate, outdated, or incomplete, or if Merito has reasonable grounds to suspect that such information is untrue, inaccurate, outdated, or incomplete, Merito may terminate your user account and refuse current or future use of any or all of the Services.</p>,
            ]
        }

    ]
}

export const Organization = {
    title: <>Organization Accounts and Administrators</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>When you sign up for an account for your organization you may specify one or more administrators. The administrators will have the right to configure the Services based on your requirements and manage end users in your organization account. If your organization account is created and configured on your behalf by a third party, it is likely that such third party has assumed administrator role for your organization. Make sure that you enter into a suitable agreement with such a third party specifying such party’s roles and restrictions as an administrator of your organization account.
                You are responsible for (i) ensuring confidentiality of your organization account password, (ii) appointing competent individuals as administrators for managing your organization account, and (iii) ensuring that all activities that occur in connection with your organization account comply with this Agreement. You understand that Merito is not responsible for account administration and internal management of the Services for you.
                You are responsible for taking necessary steps for ensuring that your organization does not lose control of the administrator accounts. You may specify a process to be followed for recovering control in the event of such loss of control of the administrator accounts by sending an email tohelpdesk@clickmerito.com, provided that the process is acceptable to Merito. In the absence of any specified administrator account recovery process, Merito may provide control of an administrator account to an individual providing proof satisfactory to Merito demonstrating authorization to act on behalf of the organization. You agree not to hold Merito liable for the consequences of any action taken by Merito in good faith in this regard.</p>,
            ]
        }

    ]
}

export const Fees = {
    title: <>Fees and Payments</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>The Services are available under subscription plans of various durations. From time to time, we may change the price of any Service or charge for use of Services that are currently available free of charge. Any increase in charges will not apply until the expiry of your then current billing cycle. You will not be charged for using any Service unless you have opted for a paid subscription plan. Any changes in Fees and Payments will be communicated to all customers with sufficient notice.
                In the event any tax such as GST, VAT, sales tax or the like is chargeable by Merito in accordance with any local, state, provincial or foreign laws with respect to your subscription to our Services ("Taxes"), Merito will invoice you for such Taxes. You agree to pay Merito such Taxes in addition to the subscription fees. Merito shall provide you with an invoice in the format prescribed by the applicable local, state, provincial or foreign laws to help you avail the applicable input tax credit for the Taxes so paid.</p>,
            ]
        }

    ]
}

export const ThirdParty = {
    title: <>Third Party Applications</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>Merito services integrate with many third party applications (hereinafter "Third Party Application(s)"). Access and use of the Third Party Applications may require acceptance of terms of service and privacy policies applicable to such Third Party Applications (hereinafter "Third Party Terms"). You are responsible for reading and understanding the Third Party Terms before accessing or using any Third Party Application. You acknowledge and agree that Merito is not liable for any Third Party Applications. While we will try to provide you with advance notice, whenever reasonably possible, you acknowledge and agree that Merito may, at any time and in our sole discretion, and without any notice to you, suspend, restrict or disable access to or remove from Merito Services, any Third Party Application, without any liability to you, including without limitation for any loss of profits, revenue, data, goodwill or other intangible losses.</p>,
            ]
        }

    ]
}

export const Grievance = {
    title: <>Grievance Redressal</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>In case of any grievance, the term as defined under Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules 2021, We can be contacted by sending an email to <a href='mailto:helpdesk@clickmerito.com'>helpdesk@clickmerito.com</a>.</p>,
            ]
        }

    ]
}

export const Communications = {
    title: <>Grievance Redressal</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>The Service may include certain communications from Merito, such as service announcements, administrative messages and newsletters. You understand that these communications shall be considered part of using the Services. As part of our policy to provide you total privacy, we also provide you the option of opting out from receiving newsletters from us. However, you will not be able to opt-out from receiving service announcements and administrative messages.</p>,
            ]
        }

    ]
}

export const Spamming = {
    title: <>Spamming and Illegal Activities</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>You agree to be solely responsible for the contents of your transmissions through the Services. You agree not to use the Services for illegal purposes or for the transmission of material that is unlawful, defamatory, insulting, harassing, libelous, invasive of another's privacy (including bodily privacy), abusive, threatening, harmful, vulgar, pornographic, paedophilic, harmful to children, obscene, racially or ethnically objectionable, or is otherwise objectionable, offends religious sentiments, promotes racism, contains viruses or malicious code, or that which infringes or may infringe intellectual property or other rights of another. You agree not to use the Services for the transmission of "junk mail", "spam", "chain letters", “phishing” or unsolicited mass distribution of email. We reserve the right to terminate your access to the Services if there are reasonable grounds to believe that you have used the Services for any illegal or unauthorized activity.</p>,
            ]
        }

    ]
}

export const Trademark = {
    title: <>Trademark</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>Merito, Merito logo, the names of individual Services and their logos are trademarks of  Jigsaw Financial Technology Pvt. Ltd. You agree not to display or use, in any manner, the Merito trademarks, without Merito’s prior permission.</p>,
            ]
        }

    ]
}

export const Indemnification = {
    title: <>Trademark</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>You agree to indemnify and hold harmless Merito, its officers, directors, employees, suppliers, and affiliates, from and against any losses, damages, fines and expenses (including attorney's fees and costs) arising out of or relating to any claims that you have used the Services in violation of another party's rights, in violation of any law, in violations of any provisions of the Agreement , or any other claim related to your use of the Services, except where such use is authorized by Merito.</p>,
            ]
        }

    ]
}

export const Governing = {
    title: <>Governing law and Jurisdiction</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>Any controversy or claim arising out of or relating to the Terms shall be settled and adjudicated exclusively by the courts of Mumbai, India in accordance with the laws of India without regard to conflict of law principles.</p>,
            ]
        }

    ]
}

export const Modification = {
    title: <>Modification of Terms of Service</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <p>We may modify this Agreement upon notice to you at any time through a service announcement or by sending email to your primary email address. If we make significant changes to the Agreement that affect your rights, you will be provided with at least 30 days advance notice of the changes by email to your primary email address. You may terminate your use of the Services by providing Merito notice by email within 30 days of being notified of the availability of the modified Agreement if the Agreement is modified in a manner that substantially affects your rights in connection with use of the Services. In the event of such termination, you will be entitled to prorated refund of the unused portion of any prepaid fees. Your continued use of the Service after the effective date of any change to the Agreement will be deemed to be your agreement to the modified Agreement.</p>,
            ]
        }

    ]
}

export const End = {
    title: <>End Of Terms Of Service</>,
    paragraph: [
        {
            subTitle: null,
            type: ParagraphType.paragraph,
            content: [
                <>If you have any questions or concerns regarding this Agreement, please contact us at <a href='mailto:helpdesk@clickmerito.com'>helpdesk@clickmerito.com</a>.</>,
            ]
        }

    ]
}