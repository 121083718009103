import { useEffect } from "react";
import Fifthsection from "../components/fifthSection/FifthSection";
import FourthSection from "../components/fourthSection/FourthSection";
import HeroSection from "../components/heroSection/HeroSection";
import SecondSection from "../components/SecondSection/SecondSection";
import SeventhSection from "../components/SeventhSection/SeventhSection";
import SixthSection from "../components/SixthSection/SixthSection";
import ThirdSection from "../components/thirdSection/ThirdSection";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <>
      <HeroSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <Fifthsection />
      <SixthSection />
      <SeventhSection />
    </>
  );
};

export default Home;
