import Paragraph from "./../PrivacyPolicy/components/Paragraph/Paragraph";
import {
  termsOfService,
  acceptance,
  Description,
  Disclaimer,
  Cookies,
  Hyperlinking,
  Privacy,
  ContentLiability,
  Obligations,
  Organization,
  Fees,
  ThirdParty,
  Grievance,
  Communications,
  Spamming,
  Trademark,
  Governing,
  Modification,
  End,
} from "./data";

import styles from "./../PrivacyPolicy/PrivacyPolicy.module.scss";
import { useEffect } from "react";

const TOS = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <section className={styles.main}>
      <div className={styles.container}>
        <h1 className={styles.headingTitle}>Terms of Service</h1>
        <Paragraph
          title={termsOfService.title}
          paragraphs={termsOfService.paragraph}
        />
      </div>
      <div className={styles.pointsContainer}>
        <Paragraph title={acceptance.title} paragraphs={acceptance.paragraph} />
        <Paragraph
          title={Description.title}
          paragraphs={Description.paragraph}
        />
        <Paragraph title={Disclaimer.title} paragraphs={Disclaimer.paragraph} />
        <Paragraph title={Cookies.title} paragraphs={Cookies.paragraph} />
        <Paragraph
          title={Hyperlinking.title}
          paragraphs={Hyperlinking.paragraph}
        />
        <Paragraph title={Privacy.title} paragraphs={Privacy.paragraph} />
        <Paragraph
          title={ContentLiability.title}
          paragraphs={ContentLiability.paragraph}
        />
        <Paragraph
          title={Obligations.title}
          paragraphs={Obligations.paragraph}
        />
        <Paragraph
          title={Organization.title}
          paragraphs={Organization.paragraph}
        />
        <Paragraph title={Fees.title} paragraphs={Fees.paragraph} />
        <Paragraph title={ThirdParty.title} paragraphs={ThirdParty.paragraph} />
        <Paragraph title={Grievance.title} paragraphs={Grievance.paragraph} />
        <Paragraph
          title={Communications.title}
          paragraphs={Communications.paragraph}
        />
        <Paragraph title={Spamming.title} paragraphs={Spamming.paragraph} />
        <Paragraph title={Trademark.title} paragraphs={Trademark.paragraph} />
        <Paragraph title={Governing.title} paragraphs={Governing.paragraph} />
        <Paragraph
          title={Modification.title}
          paragraphs={Modification.paragraph}
        />
        <Paragraph title={End.title} paragraphs={End.paragraph} />
      </div>
    </section>
  );
};

export default TOS;
