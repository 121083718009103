import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './FourthSection.module.scss';
// import required modules
import "swiper/css";
import "swiper/css/pagination";
import './swiper.css';
import SaasPlatformImage from '../../assets/images/SaasCompany.svg';
import InvestmenCompany from '../../assets/images/InvestmentCompanies.svg';
import PaymentCompany from '../../assets/images/PaymentGateway.svg';
import EcommerceCompany from '../../assets/images/EcommercePlatform.svg';
import { Autoplay, Keyboard, Mousewheel, Pagination, Scrollbar, Swiper as SwiperType } from "swiper";
import SwiperCard from './swiperCard/SwiperCard';
import { useRef, useState } from 'react';
import { FaChevronLeft as LeftButton, FaChevronRight as RightButton } from 'react-icons/fa'


const SwiperDetails = [
    {
        title: <> Improve ROAS</>,
        subTitle: <>Reduce ad wastage and run better targeted campaigns</>,
        imgAssets: InvestmenCompany
    },
    {
        title: <>Increase Inventory Turnover</>,
        subTitle: <>Product and SKU level analytics to help you double down on the winners</>,
        imgAssets: SaasPlatformImage
    },
    {
        title: <>Take Decisions, and Track Them</>,
        subTitle: <>Don’t just spot a trend. Take action on it through the report.</>,
        imgAssets: EcommerceCompany
    },
    {
        title: <>Save Time</>,
        subTitle: <>Consolidated reports from every sales channel and ad platform.</>,
        imgAssets: InvestmenCompany
    },

]


const FourthSection = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    const sliderRef = useRef(null);


    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null);

    const swiperRef = useRef<SwiperType>();

    const [firstSwiper, setFirstSwiper] = useState(null);


    return (
        <section className={styles.main}>
            <div className={styles.container}>
                <div className={styles.textContainer}>
                    What can Merito do for you?
                    {/* If the answer to these questions is a ‘yes’,
                    <br />
                    Merito is for you :) */}
                </div>
                <div className={styles.carouselContainer}>
                    <Swiper
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        grabCursor={true}
                        slidesPerView={1}
                        // slidesPerGroup={1}
                        // onBeforeInit={(swiper: any) => {
                        //     swiper.params.navigation.prevEl = navigationPrevRef.current;
                        //     swiper.params.navigation.nextEl = navigationNextRef.current;
                        // }}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        spaceBetween={60}
                        breakpoints={{
                            850: {
                                slidesPerView: 3,
                                // slidesPerGroup: 2,
                                spaceBetween: 30
                            },
                        }}
                        // autoplay={{
                        //     delay: 1500,
                        //     disableOnInteraction: false,
                        // }}
                        loop={true}
                        direction="horizontal"
                        mousewheel={{
                            forceToAxis: true,
                        }}
                        // navigation={true}
                        modules={[Mousewheel]}
                        // centeredSlidesBounds={false}
                        loopFillGroupWithBlank={true}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <SwiperCard title={width < 600 ? SwiperDetails[1].title : SwiperDetails[0].title} subtitle={width < 600 ? SwiperDetails[1].subTitle : SwiperDetails[0].subTitle} imgAsset={width < 600 ? SwiperDetails[1].imgAssets : SwiperDetails[0].imgAssets} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <SwiperCard title={width < 600 ? SwiperDetails[2].title : SwiperDetails[1].title} subtitle={width < 600 ? SwiperDetails[2].subTitle : SwiperDetails[1].subTitle} imgAsset={width < 600 ? SwiperDetails[2].imgAssets : SwiperDetails[1].imgAssets} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <SwiperCard title={width < 600 ? SwiperDetails[0].title : SwiperDetails[2].title} subtitle={width < 600 ? SwiperDetails[0].subTitle : SwiperDetails[2].subTitle} imgAsset={width < 600 ? SwiperDetails[0].imgAssets : SwiperDetails[2].imgAssets} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <SwiperCard title={SwiperDetails[3].title} subtitle={SwiperDetails[3].subTitle} imgAsset={SwiperDetails[3].imgAssets} />
                        </SwiperSlide>
                    </Swiper>


                    <div className={styles.buttonsContainer}>
                        <div ref={navigationPrevRef} onClick={() => swiperRef.current?.slidePrev()} className={styles.btnIconContainer}>
                            <LeftButton />
                        </div>
                        <div ref={navigationNextRef} onClick={() => swiperRef.current?.slideNext()} className={styles.btnIconContainer}>
                            <RightButton />
                        </div>
                    </div>
                </div>



            </div>

        </section>


    );
}

export default FourthSection;