import { Dialog } from '@mui/material';
import { useState } from 'react';

import Buttons, { ButtonStyles } from '../buttons/Buttons';
import CalendlyPopup from '../calendlyPopup/CalendlyPopup';
import EarlyAccess from '../EarlyAccess/EarlyAccess';
import { PrefilledCalendly } from '../header/header';
import styles from './SeventhSection.module.scss';


const SeventhSection = () => {
    const [showCalendly, setShowCalendly] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [prefillAnswer, setPrefillAnswer] = useState<PrefilledCalendly>({ name: "", email: "" });


    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleSave = (values) => {
        console.log(values);
        setPrefillAnswer({ name: `${values['firstName']} ${values['lastName']}`, email: `${values['emailId']}` })
        setOpenModal(false);
        setShowCalendly(true);
    }

    return (
        <section className={styles.main}>
            <div className={styles.container}>
                <div className={styles.textContainer}>
                    Early Access,
                    <br />
                    Now open.
                </div>
                <Buttons type={ButtonStyles.SECONDARY} onClick={() => setOpenModal(true)}>
                    <div className={styles.btnContainer}>
                        Get Early Access
                    </div>
                </Buttons>
                <EarlyAccess handleSave={handleSave} isOpen={openModal} handleCloseModal={handleCloseModal} setIsOpen={setOpenModal} />
                <CalendlyPopup showPopup={showCalendly} setShowPopup={setShowCalendly} prefillValues={prefillAnswer} />
            </div>
        </section >
    );
}

export default SeventhSection;