import React from 'react';
import './App.css';
import Layout from './Layout/Layout';
import { Routes, Route } from 'react-router-dom';
import Home from './Home/Home';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import EarlyAccessPage from './pages/EarlyAccess';
import TOS from './Tos/tos';


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="termsofservice" element={<TOS />} />
        </Route>
        <Route path="/earlyaccess" element={<EarlyAccessPage />} />
      </Routes>
    </>

  );
}

export default App;
