import { ParagraphContentType, ParagraphType } from '../../data';
import OrderedParagraph from './OrderedParagraph';
import styles from './Paragraph.module.scss';

// const paragraphs = [
//     <>Jigsaw and Jigsaw Financial Technology Pvt. Ltd (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Jigsaw. </>,
//     <>This Privacy Policy applies to our website, and its associated subdomains (collectively, our “Service”) alongside our application, Jigsaw. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service. </>
// ]

type Props = {
    title: React.ReactNode;
    paragraphs: ParagraphContentType[],
}

const Paragraph = ({ title, paragraphs }: Props) => {


    return (
        <div className={styles.container}>
            {title ? <div className={styles.title}>{title}</div> : null}
            <div className={styles.paraContainer}>
                {paragraphs.map((item, index) => {
                    return (
                        <>
                            {item.type == ParagraphType.paragraph ?
                                <>
                                    {item.subTitle ? <div className={styles.paraTitle}>
                                        {item.subTitle}
                                    </div> : null}
                                    {item.subHeading ? <div className={styles.subHeading}>
                                        {item.subHeading}
                                    </div> : null}
                                    <div className={styles.paragraph}>
                                        {Array.isArray(item.content) ? item.content.map((item, index) => {
                                            return <div className={styles.paragraph}>
                                                {item}
                                            </div>
                                        })
                                            : <div className={styles.paragraph}>
                                                {item.content}
                                            </div>
                                        }
                                    </div>
                                </>
                                : <OrderedParagraph subTitle={item.subTitle} subHeading={item.subHeading} listHeading={item.listHeading} type={item.type} content={item.content as React.ReactNode[]} />
                            }
                        </>
                    )
                })}
            </div>
        </div>
    );
}

export default Paragraph;