import { useEffect, useState } from 'react';
import { Dialog, MenuItem, Select, styled } from "@mui/material";
import { Field, Form } from "react-final-form";
import { customEmailValidation, domainNameValidation, mobileNumberValidation, reqiredValidation } from "../components/Footer/components/validation/validationFunctions";
import styles from './EarlyAccessPage.module.scss';
import axios from 'axios';
import { AiOutlineClose as CloseIcon } from 'react-icons/ai';
import { FORM_ERROR } from 'final-form';
import { URLSearchParams } from 'url';
import CustomTextField from '../components/Footer/components/TextField/CustomTextfield';
import Buttons, { ButtonStyles } from '../components/buttons/Buttons';
import { useSearchParams } from 'react-router-dom';
import confirmIcon from '../assets/images/confirmIcon.svg'


export const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '15px'
    },
}));

export interface ScheduleDemoAnswers {
    name: string;
    emailId: string;
    phoneNumber: string;
    companyWebsite: string;
    referredFrom: string;
    analyticsRequirements: string;
}

type Props = {
    isOpen: boolean;
    handleSave: (values) => void;
    searchParams?: URLSearchParams,
    setSearchParams?: (value: URLSearchParams) => void;
}

const EarlyAccessPage = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [isOpen, setIsOpen] = useState(false);
    const [confirmPopup, setIsConfirmPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const onSubmit = async (values) => {
        // console.log(values);
        let errors = {
            value: false,
            message: {},
        };
        let submittedValues: ScheduleDemoAnswers = {
            name: `${values['firstName']} ${values['lastName']}`,
            emailId: values['emailId'],
            phoneNumber: values['phoneNumber'],
            companyWebsite: values['companyWebsite'],
            referredFrom: values['referredFrom'],
            analyticsRequirements: values['requirements']
        }
        setLoading(true)
        try {
            const data = await axios.post(`${process.env.REACT_APP_API_URL}/api/clickmerito/leads`, submittedValues).then((res) => res.data);
            setLoading(false);
            // handleSave(values);

            clearSearchParams();
            setIsOpen(false);
            setIsConfirmPopup(true);
        } catch (error) {
            setLoading(false);
            // clearSearchParams();
            errors = {
                value: true,
                message: {
                    [FORM_ERROR]: "Something went wrong"
                }
            }
            console.log(error);
        }
        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }

    }

    useEffect(() => {
        console.log("search params", searchParams);
        let popupValue = searchParams.get('popup');
        if (popupValue == 'signup') {
            setIsOpen(true);
        }
    }, [])

    const clearSearchParams = () => {
        let earlyAccessParams = searchParams.get('popup');
        if (earlyAccessParams == 'signup') {
            let oldSearchParams = searchParams;
            oldSearchParams.delete('popup');
            setSearchParams(oldSearchParams);
        }
    }

    const handleCloseModal = () => {
        setIsOpen(false);
    }

    return (
        <>
            <CustomDialog fullWidth={true} maxWidth={"md"} open={isOpen} >
                <div className={styles.dialogContainer}>
                    <div className={styles.formMainContainer}>
                        <div className={styles.headerTitle}>
                            Sign up ✌️
                            {/* You’re just 1 form away from <br /> Early Access to a Live Demo  */}
                        </div>
                        <Form onSubmit={(values) => onSubmit(values)} render={({ handleSubmit, pristine, hasValidationErrors, submitError }) => {
                            return <>
                                <form onSubmit={handleSubmit}>

                                    <div className={styles.formSection}>
                                        <div className={styles.flexField}>
                                            <Field name='firstName' validate={(values) => reqiredValidation(values, "First Name")}>
                                                {props => {
                                                    return <CustomTextField {...props.input} maxLength={15} label={"First Name *"} value={props.input.value} onChange={props.input.onChange}
                                                        // placeholder={"Name"} 
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} />
                                                }}
                                            </Field>
                                            <Field name='lastName' maxLength={15} validate={(values) => reqiredValidation(values, "Last Name")}>
                                                {props => {
                                                    return <CustomTextField {...props.input} maxLength={15} label={"Last Name *"} value={props.input.value} onChange={props.input.onChange}
                                                        // placeholder={"Name"} 
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} />
                                                }}
                                            </Field>


                                        </div>
                                        <div className={styles.flexField}>
                                            <Field name='emailId' validate={customEmailValidation}>
                                                {props => {
                                                    return <CustomTextField {...props.input} maxLength={50} label={"Business Email ID *"} value={props.input.value} onChange={props.input.onChange}
                                                        // placeholder={"Email Id"} 
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} />
                                                }}
                                            </Field>

                                            <Field name='phoneNumber' validate={(values) => {
                                                if (!values) return "";
                                                else return mobileNumberValidation(values);
                                            }}>
                                                {props => {
                                                    return <CustomTextField {...props.input} maxLength={10} label={"Phone Number"} value={props.input.value} onChange={props.input.onChange}
                                                        // placeholder={"Phone Number"}
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} />
                                                }}
                                            </Field>
                                        </div>

                                        <div className={styles.flexField}>
                                            {/* <Field name='companyName' validate={(values) => reqiredValidation(values, "First Name")}>
                                            {props => {
                                                return <CustomTextField {...props.input} maxLength={25} label={"Company Name *"} value={props.input.value} onChange={props.input.onChange}
                                                    // placeholder={"Name"} 
                                                    error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} />
                                            }}
                                        </Field> */}

                                            <Field name='companyWebsite' validate={(values) => domainNameValidation(values, "Company Website")}>
                                                {props => {
                                                    return <CustomTextField {...props.input} maxLength={50} label={"Company Website *"} value={props.input.value} onChange={props.input.onChange}
                                                        // placeholder={"Name"} 
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} />
                                                }}
                                            </Field>

                                            <Field name='referredFrom'>
                                                {props => {
                                                    return <div className={styles.selectTextFieldContainer}>
                                                        <div className={styles.label}>How did you come across Merito?</div>
                                                        <Select
                                                            fullWidth={true}
                                                            {...props.input}
                                                            value={props.input.value}
                                                            variant={"standard"}
                                                            onChange={props.input.onChange}
                                                            style={{ fontSize: "1.5rem", color: "#666666", }}
                                                        >
                                                            <MenuItem value={"LinkedIn"}>LinkedIn</MenuItem>
                                                            <MenuItem value={"Twitter"}>Twitter</MenuItem>
                                                            <MenuItem value={"Google"}>Google</MenuItem>
                                                            <MenuItem value={"Google"}>Email</MenuItem>
                                                            <MenuItem value={"Through Someone you know"}>Someone in your network</MenuItem>
                                                        </Select>
                                                    </div>
                                                }}
                                            </Field>
                                        </div>
                                        {/* <div className={styles.halfWidthContainer}>
                                        <Field name='referredFrom'>
                                            {props => {
                                                return <div className={styles.selectTextFieldContainer}>
                                                    <div className={styles.label}>How did you come across Merito?</div>
                                                    <Select
                                                        fullWidth={true}
                                                        {...props.input}
                                                        value={props.input.value}
                                                        variant={"standard"}
                                                        onChange={props.input.onChange}
                                                        style={{ fontSize: "1.5rem", color: "#666666", }}
                                                    >
                                                        <MenuItem value={"LinkedIn"}>LinkedIn</MenuItem>
                                                        <MenuItem value={"Twitter"}>Twitter</MenuItem>
                                                        <MenuItem value={"Google"}>Google</MenuItem>
                                                        <MenuItem value={"Through Someone you know"}>Through Someone you know</MenuItem>
                                                    </Select>
                                                </div>
                                            }}
                                        </Field>
                                    </div> */}
                                        <Field name='requirements'>
                                            {props => {
                                                return <CustomTextField {...props.input} maxLength={400} label={"What kind of analytics are you looking to give your customers?"} maxRows={3} minRows={1} isTextArea={true} value={props.input.value} onChange={props.input.onChange}
                                                    // placeholder={"Message"} 
                                                    error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} />
                                            }}
                                        </Field>

                                        <div className={styles.btnMainContainer}>
                                            <Buttons type={ButtonStyles.PRIMARY} loading={loading} disabled={pristine || loading || hasValidationErrors}>
                                                <div className={styles.btnContainer}>
                                                    Sign up
                                                </div>
                                            </Buttons>

                                            {submitError && <div className={styles.formError}>
                                                {submitError}
                                            </div>}
                                        </div>
                                    </div>
                                </form>
                            </>
                        }} />
                    </div>

                    <div className={styles.closeBtnContainer}>
                        {/* <div className={styles.closeBtn} onClick={() => {
                            handleCloseModal();
                            clearSearchParams();
                        }}>
                            <CloseIcon />
                        </div> */}
                    </div>
                </div>
            </CustomDialog >

            <CustomDialog maxWidth={"sm"} open={confirmPopup}>
                <div className={styles.confirmDialogContainer}>
                    <div className={styles.imgContainer}>
                        <img src={confirmIcon} />


                    </div>
                    <div className={styles.textContainer}>
                        Thank you for showing interest.
                        <br />
                        We will get in touch shortly
                    </div>
                </div>

            </CustomDialog >
        </>

    );
}

export default EarlyAccessPage;