import { useEffect } from "react";
import OrderedParagraph from "./components/Paragraph/OrderedParagraph";
import Paragraph from "./components/Paragraph/Paragraph";
import {
  changesToPrivacyPolicy,
  childrensPrivacy,
  contactUs,
  definitions,
  informationWeCollect,
  linkWebsites,
  ParagraphContentType,
  privacyPolicy,
} from "./data";

import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <section className={styles.main}>
      <div className={styles.container}>
        <h1 className={styles.headingTitle}>Privacy Policy</h1>
        <Paragraph
          title={privacyPolicy.title}
          paragraphs={privacyPolicy.paragraph}
        />
      </div>
      <div className={styles.pointsContainer}>
        <Paragraph
          title={definitions.title}
          paragraphs={definitions.paragraph as ParagraphContentType[]}
        />
        <Paragraph
          title={informationWeCollect.title}
          paragraphs={informationWeCollect.paragraph}
        />
        <Paragraph
          title={childrensPrivacy.title}
          paragraphs={childrensPrivacy.paragraph}
        />
        <Paragraph
          title={linkWebsites.title}
          paragraphs={linkWebsites.paragraph}
        />
        <Paragraph
          title={changesToPrivacyPolicy.title}
          paragraphs={changesToPrivacyPolicy.paragraph}
        />
        <Paragraph title={contactUs.title} paragraphs={contactUs.paragraph} />
      </div>
    </section>
  );
};

export default PrivacyPolicy;
